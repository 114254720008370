<template>
  <div class="create-content-main">
    <transition name="fade">
      <div v-if="show_modal" class="delete_alarm px-5 py-3">削除成功</div>
    </transition>
    <div class="d-flex justify-content-end">
      <div>
        <b-button
          variant="info"
          class="mr-2 btn-set"
          style="background: red; color: #fff; border: none"
        >
          コンテンツ公開設定
        </b-button>
        <b-button
          v-if="isEdit"
          v-b-modal.modal-confirm-content
          variant="primary"
          class="mr-2 btn-set"
          style="background: #e5e5e5; color: #000; border: none"
        >
          オファー設定
        </b-button>
        <b-button
          v-else
          v-b-modal.modal-confirm-content
          variant="primary"
          class="mr-2 btn-set"
          style="background: #e5e5e5; color: #000; border: none"
        >
          オファー設定
        </b-button>
        <b-button
          variant="secondary"
          class="btn-return-respon back-btn"
          v-on:click="returnList()"
        >
          戻る
        </b-button>
      </div>
    </div>
    <div
      class="background-content-admin content-admin"
      style="padding-top: 12px"
    >
      <div class="create-slag-form">
        <div class="row ml-0 pt-2">
          <div
            class="col col-auto font-title font-weight-bold cpx-4 my-auto"
            style="margin-right: 10px; bottom: 5px"
            v-if="!isEdit"
          >
            公開設定
          </div>
          <div
            class="col col-auto font-title font-weight-bold mx-3 my-auto"
            style="margin-right: 10px; bottom: 5px"
            v-if="isEdit"
          >
            公開設定
          </div>
          <div class="col col-auto">
            <div class="mx-3">
              <label
                v-for="(item, index) in listSetting"
                :key="index"
                class="mr-3 position-relative py-2"
                :class="
                  typeSetting === item.value
                    ? 'btn-tab-sale-active'
                    : 'btn-tab-sale-inactive'
                "
                @click="typeSetting = item.value"
                style="font-weight: normal !important; font-size: 17px"
              >
                {{ item.text }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div v-if="typeSetting === 1">
        <!-- display modal when contents delete -->
        <form id="create-content" autocomplete="off">
          <fieldset>
            <div class="row row-input">
              <label class="col-sm-12 font-weight-bold cp-label cp-label"
                >タイトル<span class="required">(必須)</span></label
              >
              <div class="col-12 cp-4">
                <input
                  class="form-input m-0"
                  type="text"
                  v-model="content.title"
                  placeholder="タイトル"
                />
              </div>
            </div>
            <div class="row row-input">
              <div class="col-sm-12 img-custome">
                <div class="row pl-0">
                  <label class="col-sm-12 font-weight-bold cp-label cp-label"
                    >コンテンツ画像</label
                  >
                  <div class="col-sm-6 my-auto cp-4">
                    <label
                      for="fileInput"
                      class="btn label-input-file btn-info bg-0083cf"
                    >
                      アップロード
                    </label>
                  </div>
                  <div class="col-sm-6 cp-4">
                    <div class="">
                      <input
                        id="fileInput"
                        class="d-none"
                        accept="image/jpg, image/png, image/jpeg"
                        @change="uploadImg($event)"
                        type="file"
                      />
                      <div
                        v-if="previews.src"
                        class="float-md-right"
                        style="margin: 20px 0px"
                      >
                        <div class="show-preview float-md-right my-auto">
                          <img
                            v-if="content.imgCheck"
                            :src="content.imgCheck"
                            width="100%"
                            height="100%"
                            style="object-fit: contain"
                          />
                        </div>
                      </div>
                      <div
                        v-if="content.imagePre"
                        style="margin: 20px 0px"
                        class="show-preview float-right"
                      >
                        <img
                          class="preview-img"
                          width="100%"
                          height="100%"
                          style="object-fit: contain"
                          :src="content.imagePre"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="content.fileName">
              <div class="justify-content-center text-center">
                <p class="group-title">画像編集ツール</p>
              </div>
              <div class="cut">
                <cropper
                  class="cropper"
                  :src="option.img"
                  :debounce="false"
                  :stencil-props="{
                    aspectRatio: 1 / 1,
                  }"
                  @change="realTime"
                ></cropper>
              </div>
            </div>
            <div class="row row-input" hidden>
              <label class="col-sm-12 font-weight-bold cp-label cp-label"
                >商品タイプ
              </label>
              <b-form-group class="col-sm-12 cp-4" v-slot="{ ariaDescribedby }">
                <div class="d-flex group-radio-type">
                  <div class="mx-1">
                    <b-form-radio
                      v-model="content.product_type"
                      class="mr-3 d-none"
                      id="radio_1"
                      :aria-describedby="ariaDescribedby"
                      name="some-radios"
                      :value="0"
                      >オンライン</b-form-radio
                    >
                    <label
                      for="radio_1"
                      :class="
                        content.product_type === 0
                          ? 'btn-share-freesize-active py-2 px-4'
                          : 'btn-share-freesize py-2 px-4'
                      "
                      style="width: 130px"
                    >
                      オンライン
                    </label>
                  </div>
                  <div class="mx-1 text-center">
                    <b-form-radio
                      v-model="content.product_type"
                      class="mx-md-3 d-none"
                      id="radio_2"
                      :aria-describedby="ariaDescribedby"
                      name="some-radios"
                      :value="1"
                      >物販</b-form-radio
                    >
                    <label
                      for="radio_2"
                      style="width: 130px"
                      :class="
                        content.product_type === 1
                          ? 'btn-share-freesize-active py-2 px-4'
                          : 'btn-share-freesize py-2 px-4'
                      "
                    >
                      物販
                    </label>
                  </div>
                </div>
              </b-form-group>
            </div>
            <label
              class="col-sm-12 font-weight-bold cp-label cp-label"
              style="
                background: #d5d5d5;
                min-height: 40px;
                font-weight: bold;
                font-size: 1.1em;
                padding: 0 40px;
                align-items: center;
                display: flex;
                margin-bottom: 0px;
              "
              >目次を設定
            </label>
            <div class="" style="padding: 0px 40px">
              <b-button
                class="custom-button-add-chapter btn-color bg-0083cf"
                href="#"
                style=""
                @click="AddNewChapter()"
              >
                目次追加
              </b-button>
            </div>
            <draggable
              v-model="dataTableContent"
              group="people"
              @start="drag = true"
              @end="drag = false"
            >
              <div
                v-for="(item, ind) in dataTableContent"
                :key="ind"
                class="objectItem"
              >
                <div
                  v-if="item.page.length > 0"
                  style="margin-top: 20px; padding: 0px 40px"
                >
                  <div
                    class="card table-of-contentedit"
                    header="目次"
                    header-tag="header"
                    v-if="item.is_page === 0"
                    v-on:mouseenter="x_state = 1"
                    v-on:mouseleave="x_state = 0"
                  >
                    <div
                      class="card-header d-flex flex-nowrap table-of-content-chapter"
                    >
                      <input
                        class="form-input w-100 mr-4"
                        type="text"
                        v-model="item.catalogue_title"
                        placeholder=""
                      />
                      <b-icon
                        icon="gear-fill"
                        class="my-auto"
                        font-scale="2"
                        style="border-color: #ced2d8; padding: 4px"
                        v-on:click="showSlagConditionDetail(item, ind)"
                      />
                      <b-icon
                        icon="x"
                        :class="x_state_fn(x_state) ? x_show : x_hide"
                        font-scale="2"
                        style="
                          background-color: #ced2d8;
                          border-color: #ced2d8;
                          padding: 4px;
                          border-radius: 50px;
                          right: -1%;
                          top: -10px;
                          cursor: pointer;
                        "
                        v-on:click="deleteContent(ind, item, item.id)"
                      />
                    </div>
                    <div class="card-body boder-body">
                      <draggable
                        v-model="item.page"
                        group="people1"
                        @start="drag = true"
                        @end="drag = false"
                      >
                        <div
                          v-for="(ele, index) in item.page"
                          :key="index"
                          class="d-flex flex-direction-column align-items-center mb-4 w-100"
                        >
                          <div
                            class="border-titlePage mr-3 w-100 position-relative pl-0"
                          >
                            <div
                              class="text-left"
                              style="padding: 0.375rem 0.75rem"
                            >
                              {{ ele.page_title }}
                            </div>
                            <b-icon
                              icon="x"
                              :class="x_state_fn(x_state) ? x_show : x_hide"
                              font-scale="2"
                              style="
                                border-color: #ced2d8;
                                padding: 4px;
                                border-radius: 50px;
                                right: -1%;
                                top: -10px;
                                cursor: pointer;
                              "
                              v-on:click="deletePage(index, ele, ind)"
                            />
                          </div>

                          <b-icon
                            icon="gear-fill"
                            class="my-auto"
                            font-scale="2"
                            style="border-color: #ced2d8; padding: 4px"
                            v-on:click="showSlagConditionDetailPage(ind, index)"
                          />
                        </div>
                      </draggable>
                      <div>
                        <b-button
                          class="custom-button custom-button-plus mx-auto custom-button-add-chapter"
                          href="#"
                          variant="primary"
                        >
                          <b-icon
                            icon="plus"
                            aria-hidden="true"
                            style="font-size: 60px"
                            @click="OpenModalPage(item, ind)"
                          ></b-icon>
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="item.page.length > 0">
                  <div
                    class="card customListPage"
                    header="目次"
                    header-tag="header"
                    style="border: none !important; background-color: white"
                    v-if="item.is_page === 1"
                  >
                    <div class="card-body boder-body px-0">
                      <draggable
                        v-model="item.page"
                        group="people1"
                        @start="drag(event)"
                        @end="drag = false"
                        style="padding: 0px 40px"
                      >
                        <div
                          v-for="(ele, index) in item.page"
                          v-on:mouseenter="x_state_1 = 1"
                          v-on:mouseleave="x_state_1 = 0"
                          :key="index"
                          class="d-flex flex-direction-column align-items-center position-relative"
                          style="
                            padding: 0.75rem 1.25rem;
                            border: 2px solid #a4a4a4;
                            background-color: #fff;
                            border-radius: calc(0.25rem);
                            margin: 0px 0px 1.5rem;
                          "
                        >
                          <div
                            class="border-titlePage mr-4 w-100 text-left"
                            style="padding: 0.375rem 0.75rem"
                          >
                            {{ ele.page_title }}
                          </div>

                          <b-icon
                            icon="gear-fill"
                            class="my-auto"
                            font-scale="2"
                            style="border-color: #ced2d8; padding: 4px"
                            v-on:click="showSlagConditionDetailPage(ind, index)"
                          />

                          <b-icon
                            icon="x"
                            :class="x_state_fn(x_state_1) ? x_show : x_hide"
                            font-scale="2"
                            style="
                              background-color: #ced2d8;
                              border-color: #ced2d8;
                              padding: 4px;
                              border-radius: 50px;
                              right: -1%;
                              top: -10px;
                              cursor: pointer;
                            "
                            v-on:click="deletePage(index, ele, ind)"
                          />
                        </div>
                      </draggable>
                    </div>
                  </div>
                </div>
              </div>
            </draggable>
            <div
              v-if="showAddTableOfContent"
              class="text-center table-of-contentedit"
              style="padding: 0px 40px"
            >
              <b-button
                class="custom-button custom-button-plus mx-auto custom-button-add-chapter"
                href="#"
                variant="primary"
                style="border-radius: 50% !important"
              >
                <b-icon
                  icon="plus"
                  aria-hidden="true"
                  style="font-size: 60px"
                  @click="AddNewChapter()"
                ></b-icon>
              </b-button>
            </div>
            <div>
              <b-modal
                id="modal-show-page"
                modal-class="modal-show-page"
                :size="showAddPage === 1 || showAddPage === 2 ? 'xl' : 'md'"
                ref="modal-show-page"
                :hide-footer="true"
              >
                <template #modal-header="{}">
                  <b-button
                    v-if="showAddPage === 1 || showAddPage === 2"
                    size="sm"
                    style="
                      background-color: white;
                      border-color: white;
                      font-size: 15px;
                    "
                    class=""
                    @click="backMenuAdd()"
                  >
                    <i class="fas fa-arrow-left"></i>
                  </b-button>
                  <input
                    v-if="showAddPage === 1 || showAddPage === 2"
                    placeholder="タイトル検索"
                    class="form-control w-50 ml-0"
                    style="margin-right: 20%"
                    v-model="key_word"
                  />
                  <div class="" v-if="showAddPage === 1 || showAddPage === 2">
                    <b-button
                      v-on:click.prevent="updateConditionChapter()"
                      :disabled="isLoading"
                      variant="info"
                      class="mr-2"
                      >ページ挿入
                    </b-button>
                  </div>
                  <b-button
                    size="sm"
                    style="background-color: white; border-color: white"
                    class="close"
                    :class="{ 'ml-0': showAddPage === 1 || showAddPage === 2 }"
                    @click="closeModal()"
                  >
                    <i class="fas fa-times"></i>
                  </b-button>
                </template>
                <div
                  class="page-list-content p-0"
                  style="max-height: 60vh; overflow-y: auto; overflow-x: hidden"
                >
                  <div class="row px-4" v-if="selectAdd === 1">
                    <div
                      class="col-6 justify-content-center"
                      @click="addPage()"
                    >
                      <div class="customSelectAdd text-center">ページ追加</div>
                    </div>
                    <div
                      class="col-6 justify-content-center"
                      @click="addChapter()"
                    >
                      <div class="customSelectAdd text-center">章追加</div>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="showAddPage === 1">
                      <Tables
                        :items="listDataPage"
                        :fields="fields"
                        :itemsPerPageSelect="{}"
                        class="cpx-4 table-modal-chapter"
                      >
                        <template v-slot:title="{ item }">
                          <td style="" class="py-2">
                            <div class="text-truncate-mess">
                              {{ item.page_title }}
                            </div>
                          </td>
                        </template>
                        <template v-slot:updated_at="{ item }">
                          <td style="" class="py-2">
                            <div class="text-truncate-mess">
                              {{
                                moment(new Date(item.updated_at)).format(
                                  "YYYY/MM/DD HH:mm:ss"
                                )
                              }}
                            </div>
                          </td>
                        </template>
                        <template v-slot:action="{ item, index }">
                          <td
                            class="my-auto pl-2 pr-1 d-flex justify-content-center"
                          >
                            <div class="w-5">
                              <CButton
                                class="mx-1"
                                :class="
                                  item._checked === true
                                    ? 'btn-del'
                                    : 'btn-other'
                                "
                                square
                                variant="outline"
                                size="sm"
                                v-b-modal.modal-detail-note
                                v-on:click="ClickAddPage(item, index)"
                                :disabled="item._checked"
                              >
                                <span v-if="item._checked === true">
                                  選択済み
                                </span>
                                <span v-else>選択</span>
                              </CButton>
                            </div>
                          </td>
                        </template>
                      </Tables>
                    </div>
                    <div v-if="showAddPage === 2">
                      <Tables
                        :items="listDataPage"
                        :fields="fields"
                        :itemsPerPageSelect="{}"
                        class="cpx-4 table-modal-chapter"
                      >
                        <template v-slot:title="{ item }">
                          <td style="" class="py-2">
                            <div class="text-truncate-mess">
                              {{ item.page_title }}
                            </div>
                          </td>
                        </template>
                        <template v-slot:updated_at="{ item }">
                          <td style="" class="py-2">
                            <div class="text-truncate-mess">
                              {{
                                moment(new Date(item.updated_at)).format(
                                  "YYYY/MM/DD HH:mm:ss"
                                )
                              }}
                            </div>
                          </td>
                        </template>
                        <template v-slot:action="{ item, index }">
                          <td
                            class="my-auto pl-2 pr-1 d-flex justify-content-center"
                          >
                            <div class="w-5">
                              <CButton
                                class="mx-1"
                                :class="
                                  item._checked === true
                                    ? 'btn-del'
                                    : 'btn-other'
                                "
                                square
                                variant="outline"
                                size="sm"
                                v-b-modal.modal-detail-note
                                v-on:click="ClickPageChecked(item, index)"
                              >
                                <span v-if="item._checked === true">
                                  選択済み
                                </span>
                                <span v-else>選択</span>
                              </CButton>
                            </div>
                          </td>
                        </template>
                      </Tables>
                    </div>
                  </div>
                </div>
              </b-modal>
            </div>
            <div>
              <b-modal
                id="modal-add-page"
                size="xl"
                modal-class="modal-add-page"
                ref="modal-add-page"
                :hide-footer="true"
              >
                <template #modal-header="{}">
                  <input
                    placeholder="タイトル検索"
                    class="form-control w-50 ml-5"
                    style="margin-right: 20%"
                    v-model="key_word"
                  />
                  <div class="">
                    <b-button
                      v-on:click.prevent="addPageInChapter()"
                      :disabled="isLoading"
                      variant="info"
                      class="mr-4"
                      >ページ挿入
                    </b-button>
                  </div>
                  <b-button
                    size="sm"
                    style="background-color: white; border-color: white"
                    class="close ml-0"
                    @click="closeModalAddPage()"
                  >
                    <i class="fas fa-times"></i>
                  </b-button>
                </template>
                <div
                  class="page-list-content p-0"
                  style="max-height: 60vh; overflow-y: auto; overflow-x: hidden"
                >
                  <Tables
                    :items="listDataPage"
                    :fields="fields"
                    :itemsPerPageSelect="{}"
                    class="cpx-4"
                  >
                    <template v-slot:title="{ item }">
                      <td style="" class="py-2">
                        <div class="text-truncate-mess">
                          {{ item.page_title }}
                        </div>
                      </td>
                    </template>
                    <template v-slot:updated_at="{ item }">
                      <td style="" class="py-2">
                        <div class="text-truncate-mess">
                          {{
                            item.updated_at
                              ? new Date(item.updated_at).toLocaleString(
                                  "ja-JP",
                                  {
                                    timeZone: "Asia/Tokyo",
                                  }
                                )
                              : ""
                          }}
                        </div>
                      </td>
                    </template>
                    <template v-slot:action="{ item, index }">
                      <td
                        class="my-auto pl-2 pr-1 d-flex justify-content-center"
                      >
                        <div class="w-5">
                          <CButton
                            class="mx-1"
                            :class="
                              item._selected === true ? 'btn-del' : 'btn-other'
                            "
                            square
                            variant="outline"
                            size="sm"
                            v-b-modal.modal-detail-note
                            v-on:click="ClickPageAddChecked(item, index)"
                          >
                            <span v-if="item._selected === true">選択済み</span>
                            <span v-else>選択</span>
                          </CButton>
                        </div>
                      </td>
                    </template>
                  </Tables>
                </div>
              </b-modal>
            </div>
            <div>
              <b-modal
                id="modal-Condition-Page"
                modal-class="modalCustom"
                style="width: 720px"
                hide-header
              >
                <div class="d-block text-center">
                  <!-- <h5>削除しますと復元できませんのでご注意ください</h5> -->
                </div>
                <!-- Content Condition -->
                <div class="px-3 customBackGround">
                  <div class="p-2">
                    <div class="row mb-3 row-input pl-3">
                      <label class="col-sm-12 font-weight-bold pl-0"
                        >基本設定(以下のコンテンツを持っている人)</label
                      >
                      <div
                        class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                      >
                        <div class="custom-select-2 w-100 custome-multi-slag">
                          <multiselect
                            class="col-sm-12 col-lg-12 p-0"
                            v-model="content_condition_1"
                            placeholder="コンテンツを選択してください"
                            label="text"
                            track-by="value"
                            :options="listContentOptions"
                            :multiple="true"
                            :taggable="false"
                            :showNoResults="false"
                            :select-label="'入力して選択する'"
                            :deselect-label="'入力して削除する'"
                            :selected-label="'選択された'"
                            :loading="loadingContent"
                            @input="changeMutiSelectContentPageCondition()"
                            v-bind:class="{
                              'error-input': errorContentIdRequired,
                            }"
                          ></multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3 row-input pl-3">
                      <label class="col-sm-12 font-weight-bold pl-0"
                        >and1(基本設定とand1両方のコンテンツを持つユーザーだけに表示)</label
                      >
                      <div
                        class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                      >
                        <div class="custom-select-2 w-100 custome-multi-slag">
                          <multiselect
                            class="col-sm-12 col-lg-12 p-0"
                            v-model="content_condition_2"
                            placeholder="コンテンツを選択してください"
                            label="text"
                            track-by="value"
                            :options="listContentOptions"
                            :multiple="true"
                            :taggable="false"
                            :showNoResults="false"
                            :select-label="'入力して選択する'"
                            :deselect-label="'入力して削除する'"
                            :selected-label="'選択された'"
                            :loading="loadingContent"
                            @input="changeMutiSelectContentPageCondition()"
                            :disabled="disabledConditionContentPage2 === true"
                            v-bind:class="{
                              'error-input': errorContentIdRequired,
                            }"
                          ></multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3 row-input pl-3">
                      <label class="col-sm-12 font-weight-bold pl-0"
                        >and2(基本設定+and1+2の3点のコンテンツを持つユーザーだけに表示)</label
                      >
                      <div
                        class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                      >
                        <div class="custom-select-2 w-100 custome-multi-slag">
                          <multiselect
                            class="col-sm-12 col-lg-12 p-0"
                            v-model="content_condition_3"
                            placeholder="コンテンツを選択してください"
                            label="text"
                            track-by="value"
                            :options="listContentOptions"
                            :multiple="true"
                            :taggable="false"
                            :showNoResults="false"
                            :select-label="'入力して選択する'"
                            :deselect-label="'入力して削除する'"
                            :selected-label="'選択された'"
                            :loading="loadingContent"
                            :disabled="disabledConditionContentPage3 === true"
                            v-bind:class="{
                              'error-input': errorContentIdRequired,
                            }"
                          ></multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3 row-input pl-3">
                      <label class="col-sm-12 font-weight-bold pl-0"
                        >非表示（以下のコンテンツを持つユーザーは非表示）</label
                      >
                      <div
                        class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                      >
                        <div class="custom-select-2 w-100 custome-multi-slag">
                          <multiselect
                            class="col-sm-12 col-lg-12 p-0"
                            v-model="content_condition_4"
                            placeholder="コンテンツを選択してください"
                            label="text"
                            track-by="value"
                            :options="listContentOptions"
                            :multiple="true"
                            :taggable="false"
                            :showNoResults="false"
                            :select-label="'入力して選択する'"
                            :deselect-label="'入力して削除する'"
                            :selected-label="'選択された'"
                            :loading="loadingContent"
                          ></multiselect>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Slag condition  -->
                <div class="px-3 mt-4 customBackGround">
                  <div class="p-2">
                    <div class="row mb-3 row-input pl-3">
                      <label class="col-sm-12 font-weight-bold pl-0"
                        >基本設定(以下のスラグを持っている人)</label
                      >
                      <div
                        class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                      >
                        <div class="custom-select-2 w-100 custome-multi-slag">
                          <multiselect
                            v-model="slag_condition_1"
                            tag-placeholder="スラグを選択してください"
                            placeholder="スラグを選択してください"
                            label="slag_name"
                            track-by="slag_name"
                            :options="listSlagOptions"
                            :multiple="true"
                            :taggable="false"
                            :showNoResults="false"
                            :max-height="200"
                            select-label="入力して選択する"
                            selected-label="選択された"
                            deselect-label="入力して削除する"
                            @input="changeMutiSelectSlagPageCondition()"
                          >
                            <template v-slot:no-options>
                              リストは空です
                            </template>
                          </multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3 row-input pl-3">
                      <label class="col-sm-12 font-weight-bold pl-0"
                        >and1(基本設定とand1両方のスラグを持つユーザーだけに表示)</label
                      >
                      <div
                        class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                      >
                        <div class="custom-select-2 w-100 custome-multi-slag">
                          <multiselect
                            v-model="slag_condition_2"
                            tag-placeholder="スラグを選択してください"
                            placeholder="スラグを選択してください"
                            label="slag_name"
                            track-by="slag_name"
                            :options="listSlagOptions"
                            :multiple="true"
                            :taggable="false"
                            :showNoResults="false"
                            :max-height="200"
                            select-label="入力して選択する"
                            selected-label="選択された"
                            deselect-label="入力して削除する"
                            @input="changeMutiSelectSlagPageCondition()"
                            :disabled="disabledConditionSlagPage2 === true"
                          >
                            <template v-slot:no-options>
                              リストは空です
                            </template>
                          </multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3 row-input pl-3">
                      <label class="col-sm-12 font-weight-bold pl-0"
                        >and2(基本設定+and1+2の3点のスラグを持つユーザーだけに表示)</label
                      >
                      <div
                        class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                      >
                        <div class="custom-select-2 w-100 custome-multi-slag">
                          <multiselect
                            v-model="slag_condition_3"
                            tag-placeholder="スラグを選択してください"
                            placeholder="スラグを選択してください"
                            label="slag_name"
                            track-by="slag_name"
                            :options="listSlagOptions"
                            :multiple="true"
                            :taggable="false"
                            :showNoResults="false"
                            :max-height="200"
                            select-label="入力して選択する"
                            selected-label="選択された"
                            deselect-label="入力して削除する"
                            :disabled="disabledConditionSlagPage3 === true"
                          >
                            <template v-slot:no-options>
                              リストは空です
                            </template>
                          </multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3 row-input pl-3">
                      <label class="col-sm-12 font-weight-bold pl-0"
                        >非表示（以下のスラグを持つユーザーは非表示）</label
                      >
                      <div
                        class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                      >
                        <div class="custom-select-2 w-100 custome-multi-slag">
                          <multiselect
                            v-model="slag_condition_4"
                            tag-placeholder="スラグを選択してください"
                            placeholder="スラグを選択してください"
                            label="slag_name"
                            track-by="slag_name"
                            :options="listSlagOptions"
                            :multiple="true"
                            :taggable="false"
                            :showNoResults="false"
                            :max-height="200"
                            select-label="入力して選択する"
                            selected-label="選択された"
                            deselect-label="入力して削除する"
                          >
                            <template v-slot:no-options>
                              リストは空です
                            </template>
                          </multiselect>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <template #modal-footer="{}">
                  <div class="">
                    <b-button
                      v-on:click.prevent="updateConditionByPage()"
                      :disabled="isLoading"
                      variant="info"
                      class="mr-4"
                      >アップデート
                    </b-button>
                    <b-button
                      variant="secondary"
                      @click="closeModalConditionPage()"
                      class="mx-2 back-btn"
                      >戻る</b-button
                    >
                  </div>
                </template>
              </b-modal>
            </div>
            <div>
              <b-modal
                id="modal-detail-slagCondition"
                modal-class="modalCustom"
                style="width: 720px"
                hide-header
              >
                <div class="d-block text-center">
                  <!-- <h5>削除しますと復元できませんのでご注意ください</h5> -->
                </div>
                <!-- Content Condition -->
                <div class="px-3 customBackGround">
                  <div class="p-2">
                    <div class="row mb-3 row-input pl-3">
                      <label class="col-sm-12 font-weight-bold pl-0"
                        >基本設定(以下のコンテンツを持っている人)</label
                      >
                      <div
                        class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                      >
                        <div class="custom-select-2 w-100 custome-multi-slag">
                          <multiselect
                            class="col-sm-12 col-lg-12 p-0"
                            v-model="content_condition_1"
                            placeholder="コンテンツを選択してください"
                            label="text"
                            track-by="value"
                            :options="listContentOptions"
                            :multiple="true"
                            :taggable="false"
                            :showNoResults="false"
                            :select-label="'入力して選択する'"
                            :deselect-label="'入力して削除する'"
                            :selected-label="'選択された'"
                            :loading="loadingContent"
                            @input="changeMutiSelectContentCondition()"
                            v-bind:class="{
                              'error-input': errorContentIdRequired,
                            }"
                          ></multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3 row-input pl-3">
                      <label class="col-sm-12 font-weight-bold pl-0"
                        >and1(基本設定とand1両方のコンテンツを持つユーザーだけに表示)</label
                      >
                      <div
                        class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                      >
                        <div class="custom-select-2 w-100 custome-multi-slag">
                          <multiselect
                            class="col-sm-12 col-lg-12 p-0"
                            v-model="content_condition_2"
                            placeholder="コンテンツを選択してください"
                            label="text"
                            track-by="value"
                            :options="listContentOptions"
                            :multiple="true"
                            :taggable="false"
                            :showNoResults="false"
                            :select-label="'入力して選択する'"
                            :deselect-label="'入力して削除する'"
                            :selected-label="'選択された'"
                            :loading="loadingContent"
                            @input="changeMutiSelectContentCondition()"
                            :disabled="disabledConditionContent2 === true"
                            v-bind:class="{
                              'error-input': errorContentIdRequired,
                            }"
                          ></multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3 row-input pl-3">
                      <label class="col-sm-12 font-weight-bold pl-0"
                        >and2(基本設定+and1+2の3点のコンテンツを持つユーザーだけに表示)</label
                      >
                      <div
                        class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                      >
                        <div class="custom-select-2 w-100 custome-multi-slag">
                          <multiselect
                            class="col-sm-12 col-lg-12 p-0"
                            v-model="content_condition_3"
                            placeholder="コンテンツを選択してください"
                            label="text"
                            track-by="value"
                            :options="listContentOptions"
                            :multiple="true"
                            :taggable="false"
                            :showNoResults="false"
                            :select-label="'入力して選択する'"
                            :deselect-label="'入力して削除する'"
                            :selected-label="'選択された'"
                            :loading="loadingContent"
                            :disabled="disabledConditionContent3 === true"
                            v-bind:class="{
                              'error-input': errorContentIdRequired,
                            }"
                          ></multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3 row-input pl-3">
                      <label class="col-sm-12 font-weight-bold pl-0"
                        >非表示（以下のコンテンツを持つユーザーは非表示）</label
                      >
                      <div
                        class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                      >
                        <div class="custom-select-2 w-100 custome-multi-slag">
                          <multiselect
                            class="col-sm-12 col-lg-12 p-0"
                            v-model="content_condition_4"
                            placeholder="コンテンツを選択してください"
                            label="text"
                            track-by="value"
                            :options="listContentOptions"
                            :multiple="true"
                            :taggable="false"
                            :showNoResults="false"
                            :select-label="'入力して選択する'"
                            :deselect-label="'入力して削除する'"
                            :selected-label="'選択された'"
                            :loading="loadingContent"
                          ></multiselect>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Slag condition  -->
                <div class="px-3 mt-4 customBackGround">
                  <div class="p-2">
                    <div class="row mb-3 row-input pl-3">
                      <label class="col-sm-12 font-weight-bold pl-0"
                        >基本設定(以下のスラグを持っている人)</label
                      >
                      <div
                        class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                      >
                        <div class="custom-select-2 w-100 custome-multi-slag">
                          <multiselect
                            v-model="slag_condition_1"
                            tag-placeholder="スラグを選択してください"
                            placeholder="スラグを選択してください"
                            label="slag_name"
                            track-by="slag_name"
                            :options="listSlagOptions"
                            :multiple="true"
                            :taggable="false"
                            :showNoResults="false"
                            :max-height="200"
                            select-label="入力して選択する"
                            selected-label="選択された"
                            deselect-label="入力して削除する"
                            @input="changeMutiSelectSlagCondition()"
                          >
                            <template v-slot:no-options>
                              リストは空です
                            </template>
                          </multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3 row-input pl-3">
                      <label class="col-sm-12 font-weight-bold pl-0"
                        >and1(基本設定とand1両方のスラグを持つユーザーだけに表示)</label
                      >
                      <div
                        class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                      >
                        <div class="custom-select-2 w-100 custome-multi-slag">
                          <multiselect
                            v-model="slag_condition_2"
                            tag-placeholder="スラグを選択してください"
                            placeholder="スラグを選択してください"
                            label="slag_name"
                            track-by="slag_name"
                            :options="listSlagOptions"
                            :multiple="true"
                            :taggable="false"
                            :showNoResults="false"
                            :max-height="200"
                            select-label="入力して選択する"
                            selected-label="選択された"
                            deselect-label="入力して削除する"
                            @input="changeMutiSelectSlagCondition()"
                            :disabled="disabledConditionSlag2 === true"
                          >
                            <template v-slot:no-options>
                              リストは空です
                            </template>
                          </multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3 row-input pl-3">
                      <label class="col-sm-12 font-weight-bold pl-0"
                        >and2(基本設定+and1+2の3点のスラグを持つユーザーだけに表示)</label
                      >
                      <div
                        class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                      >
                        <div class="custom-select-2 w-100 custome-multi-slag">
                          <multiselect
                            v-model="slag_condition_3"
                            tag-placeholder="スラグを選択してください"
                            placeholder="スラグを選択してください"
                            label="slag_name"
                            track-by="slag_name"
                            :options="listSlagOptions"
                            :multiple="true"
                            :taggable="false"
                            :showNoResults="false"
                            :max-height="200"
                            select-label="入力して選択する"
                            selected-label="選択された"
                            deselect-label="入力して削除する"
                            :disabled="disabledConditionSlag3 === true"
                          >
                            <template v-slot:no-options>
                              リストは空です
                            </template>
                          </multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3 row-input pl-3">
                      <label class="col-sm-12 font-weight-bold pl-0"
                        >非表示（以下のスラグを持つユーザーは非表示）</label
                      >
                      <div
                        class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                      >
                        <div class="custom-select-2 w-100 custome-multi-slag">
                          <multiselect
                            v-model="slag_condition_4"
                            tag-placeholder="スラグを選択してください"
                            placeholder="スラグを選択してください"
                            label="slag_name"
                            track-by="slag_name"
                            :options="listSlagOptions"
                            :multiple="true"
                            :taggable="false"
                            :showNoResults="false"
                            :max-height="200"
                            select-label="入力して選択する"
                            selected-label="選択された"
                            deselect-label="入力して削除する"
                          >
                            <template v-slot:no-options>
                              リストは空です
                            </template>
                          </multiselect>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <template #modal-footer="{}">
                  <div class="">
                    <b-button
                      v-on:click.prevent="updateConditionByChapter()"
                      :disabled="isLoading"
                      variant="info"
                      class="mr-4"
                      >アップデート
                    </b-button>
                    <b-button
                      variant="secondary back-btn"
                      @click="closeModalCondition()"
                      class="mx-2"
                      >戻る</b-button
                    >
                  </div>
                </template>
              </b-modal>
            </div>
          </fieldset>
        </form>
      </div>
      <div v-if="typeSetting === 2" style="padding-top: 2px">
        <!-- <div class="justify-content-center text-center">
          <p class="group-title">基本設定</p>
        </div> -->
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label cp-label"
            >ライブラリに表示非表示条件を設定する
          </label>
          <div style="margin: 20px 0px; padding: 0px 40px">
            <div class="btn-toggle-active">
              <input
                v-model="content.condition_display_library"
                class="m-0"
                type="radio"
                name="radio-type-show"
                value="0"
                id="radio-type-show-0"
              />
              <label
                for="radio-type-show-0"
                :class="
                  content.condition_display_library == 0
                    ? 'btn-share-freesize-active py-2 px-4'
                    : 'btn-share-freesize py-2 px-4'
                "
                style="min-width: 200px"
                >設定しない</label
              >
            </div>
            <div class="btn-toggle-active">
              <input
                v-model="content.condition_display_library"
                class="m-0"
                type="radio"
                name="radio-type-show"
                value="1"
                id="radio-type-show-1"
              />
              <label
                for="radio-type-show-1"
                :class="
                  content.condition_display_library == 1
                    ? 'btn-share-freesize-active py-2 px-4'
                    : 'btn-share-freesize py-2 px-4'
                "
                style="width: 200px"
                >コンテンツ所持設定</label
              >
            </div>
            <div class="btn-toggle-active">
              <input
                v-model="content.condition_display_library"
                class="m-0"
                type="radio"
                name="radio-type-show"
                value="2"
                id="radio-type-show-2"
              />
              <label
                for="radio-type-show-2"
                :class="
                  content.condition_display_library == 2
                    ? 'btn-share-freesize-active py-2 px-4'
                    : 'btn-share-freesize py-2 px-4'
                "
                style="width: 200px"
                >スラグ所持設定</label
              >
            </div>
            <div class="btn-toggle-active">
              <input
                v-model="content.condition_display_library"
                class="m-0"
                type="radio"
                name="radio-type-show"
                id="radio-type-show-3"
                :value="3"
              />
              <label
                for="radio-type-show-3"
                :class="
                  content.condition_display_library == 3
                    ? 'btn-share-freesize-active py-2 px-4'
                    : 'btn-share-freesize py-2 px-4'
                "
                style="min-width: 200px"
                >全員に表示（オススメしません）</label
              >
            </div>
            <div class="btn-toggle-active">
              <input
                v-model="content.condition_display_library"
                class="m-0"
                type="radio"
                name="radio-type-show"
                id="radio-type-show-4"
                :value="4"
              />
              <label
                for="radio-type-show-4"
                :class="
                  content.condition_display_library == 4
                    ? 'btn-share-freesize-active py-2 px-4'
                    : 'btn-share-freesize py-2 px-4'
                "
                style="width: 200px"
                >全員非表示にする</label
              >
            </div>
          </div>
        </div>
        <!--設定しない-->
        <div v-if="content.condition_display_library == 0">
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >表示条件を満たしていない場合のリダイレクト先URL</label
            >
            <div class="cp-4 col-12">
              <input
                class="form-input m-0"
                type="text"
                v-model="content.content_url_redirect"
              />
            </div>
          </div>
        </div>
        <!--全員非表示にする-->
        <div v-if="content.condition_display_library == 4">
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >非表示条件に該当する場合のリダイレクト先URL</label
            >
            <div class="cp-4 col-12">
              <input
                class="form-input m-0"
                type="text"
                v-model="content.content_url_cancel"
              />
            </div>
          </div>
        </div>
        <!--コンテンツ-->
        <div
          v-if="
            content.condition_display_library == constant_display['SET_CONTENT']
          "
        >
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label">基本設定</label>
            <div
              class="d-flex justify-content-between align-items-center add-slag"
              style="margin: 20px 40px; padding: 0; width: 100%"
            >
              <div class="custom-select-2 w-100 custome-multi-slag">
                <multiselect
                  v-model="content.content_basic"
                  tag-placeholder=""
                  placeholder=""
                  label="text"
                  track-by="text"
                  :options="listContentOptions"
                  :multiple="true"
                  :max-height="200"
                  select-label="入力して選択する"
                  selected-label="選択された"
                  deselect-label="入力して削除する"
                >
                  <template slot="noOptions"> リストは空です </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >and1（基本設定とand1両方のコンテンツを持つユーザーだけに表示）
            </label>
            <div
              class="d-flex justify-content-between align-items-center add-slag"
              style="margin: 20px 40px; padding: 0; width: 100%"
            >
              <div class="custom-select-2 w-100 custome-multi-slag">
                <multiselect
                  v-model="content.content_basic1"
                  :disabled="content.content_basic.length > 0 ? false : true"
                  tag-placeholder=""
                  placeholder=""
                  label="text"
                  track-by="text"
                  :options="listContentOptions"
                  :multiple="true"
                  :max-height="200"
                  select-label="入力して選択する"
                  selected-label="選択された"
                  deselect-label="入力して削除する"
                >
                  <template slot="noOptions"> リストは空です </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >and2（基本設定＋and1＋and2をコンテンツを持つユーザーだけに表示）
            </label>
            <div
              class="d-flex justify-content-between align-items-center add-slag"
              style="margin: 20px 40px; padding: 0; width: 100%"
            >
              <div class="custom-select-2 w-100 custome-multi-slag">
                <multiselect
                  v-model="content.content_basic2"
                  :disabled="
                    content.content_basic.length > 0 &&
                    content.content_basic1.length > 0
                      ? false
                      : true
                  "
                  tag-placeholder=""
                  placeholder=""
                  label="text"
                  track-by="text"
                  :options="listContentOptions"
                  :multiple="true"
                  :max-height="200"
                  select-label="入力して選択する"
                  selected-label="選択された"
                  deselect-label="入力して削除する"
                >
                  <template slot="noOptions"> リストは空です </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >表示条件を満たしていない場合のリダイレクト先URL</label
            >
            <div class="cp-4 col-12">
              <input
                class="form-input m-0"
                type="text"
                v-model="content.content_url_redirect"
              />
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >非表示（以下のコンテンツを持つユーザーは非表示）
            </label>
            <div
              class="d-flex justify-content-between align-items-center add-slag"
              style="margin: 20px 40px; padding: 0; width: 100%"
            >
              <div class="custom-select-2 w-100 custome-multi-slag">
                <multiselect
                  v-model="content.content_hidden"
                  tag-placeholder=""
                  placeholder=""
                  label="text"
                  track-by="text"
                  :options="listContentOptions"
                  :multiple="true"
                  :max-height="200"
                  select-label="入力して選択する"
                  selected-label="選択された"
                  deselect-label="入力して削除する"
                >
                  <template slot="noOptions"> リストは空です </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >非表示条件に該当する場合のリダイレクト先URL</label
            >
            <div class="cp-4 col-12">
              <input
                class="form-input m-0"
                type="text"
                v-model="content.content_url_cancel"
              />
            </div>
          </div>
        </div>
        <!--スラグ-->
        <div
          v-if="
            content.condition_display_library == constant_display['SET_SLAG']
          "
        >
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label">基本設定</label>
            <div
              class="d-flex justify-content-between align-items-center add-slag"
              style="margin: 20px 40px; padding: 0; width: 100%"
            >
              <div class="custom-select-2 w-100 custome-multi-slag">
                <multiselect
                  v-model="content.slags_basic"
                  tag-placeholder="購入付与スラグを追加してください"
                  placeholder="購入付与スラグを追加してください"
                  label="slag_name"
                  track-by="slag_name"
                  :options="listSlagOptions"
                  :multiple="true"
                  :max-height="200"
                  select-label="入力して選択する"
                  selected-label="選択された"
                  deselect-label="入力して削除する"
                >
                  <template slot="noOptions"> リストは空です </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >and1（基本設定とand1両方のスラグを持つユーザーだけに表示）
            </label>
            <div
              class="d-flex justify-content-between align-items-center add-slag"
              style="margin: 20px 40px; padding: 0; width: 100%"
            >
              <div class="custom-select-2 w-100 custome-multi-slag">
                <multiselect
                  v-model="content.slags_basic1"
                  :disabled="content.slags_basic.length > 0 ? false : true"
                  tag-placeholder=""
                  placeholder=""
                  label="slag_name"
                  track-by="slag_name"
                  :options="listSlagOptions"
                  :multiple="true"
                  :max-height="200"
                  select-label="入力して選択する"
                  selected-label="選択された"
                  deselect-label="入力して削除する"
                >
                  <template slot="noOptions"> リストは空です </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >and2（基本設定＋and1＋and2をスラグを持つユーザーだけに表示
            </label>
            <div
              class="d-flex justify-content-between align-items-center add-slag"
              style="margin: 20px 40px; padding: 0; width: 100%"
            >
              <div class="custom-select-2 w-100 custome-multi-slag">
                <multiselect
                  v-model="content.slags_basic2"
                  :disabled="
                    content.slags_basic.length > 0 &&
                    content.slags_basic1.length > 0
                      ? false
                      : true
                  "
                  tag-placeholder=""
                  placeholder=""
                  label="slag_name"
                  track-by="slag_name"
                  :options="listSlagOptions"
                  :multiple="true"
                  :max-height="200"
                  select-label="入力して選択する"
                  selected-label="選択された"
                  deselect-label="入力して削除する"
                >
                  <template slot="noOptions"> リストは空です </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >表示条件を満たしていない場合のリダイレクト先URL</label
            >
            <div class="cp-4 col-12">
              <input
                class="form-input m-0"
                type="text"
                v-model="content.content_url_redirect"
              />
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >非表示（以下のスラグを持つユーザーは非表示）
            </label>
            <div
              class="d-flex justify-content-between align-items-center add-slag"
              style="margin: 20px 40px; padding: 0; width: 100%"
            >
              <div class="custom-select-2 w-100 custome-multi-slag">
                <multiselect
                  v-model="content.slag_hidden"
                  tag-placeholder=""
                  placeholder=""
                  label="slag_name"
                  track-by="slag_name"
                  :options="listSlagOptions"
                  :multiple="true"
                  :max-height="200"
                  select-label="入力して選択する"
                  selected-label="選択された"
                  deselect-label="入力して削除する"
                >
                  <template slot="noOptions"> リストは空です </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >非表示条件に該当する場合のリダイレクト先URL</label
            >
            <div class="cp-4 col-12">
              <input
                class="form-input m-0"
                type="text"
                v-model="content.content_url_cancel"
              />
            </div>
          </div>
        </div>
        <div
          v-if="
            content.condition_display_library !=
              constant_display['SET_CONTENT'] &&
            content.condition_display_library != constant_display['SET_SLAG'] &&
            content.condition_display_library != 0 &&
            content.condition_display_library != 3 &&
            content.condition_display_library != 4
          "
        >
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >表示条件を満たしていない場合のリダイレクト先URL</label
            >
            <div class="cp-4 col-12">
              <input
                class="form-input m-0"
                type="text"
                v-model="content.content_url_redirect"
              />
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >非表示条件に該当する場合のリダイレクト先URL</label
            >
            <div class="col-12 cp-4">
              <input
                class="form-input m-0"
                type="text"
                v-model="content.content_url_cancel"
              />
            </div>
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label cp-label"
            >著者名<span class="required">(必須)</span>
          </label>
          <div class="col-sm-12 cp-4">
            <b-form-select
              v-if="admin_info.user_type != type_author"
              class="w-100 custome-text-select m-0"
              id="select-auth"
              style="margin: 20px 0px"
              v-model="content.author_id"
              :options="listAuthorOptions"
            ></b-form-select>
            <input
              v-else
              class="form-input m-0 col-sm-12"
              type="text"
              disabled
              v-model="content.author_name"
            />
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label cp-label"
            >カテゴリ</label
          >
          <div class="col-12 cp-4 d-flex">
            <multiselect
              v-model="content.categories"
              tag-placeholder=""
              placeholder=""
              label="category_name"
              track-by="category_name"
              :options="listCategoryOptions"
              :multiple="true"
              :max-height="200"
              select-label="入力して選択する"
              selected-label="選択された"
              deselect-label="入力して削除する"
            >
              <template slot="noOptions"> リストは空です </template>
            </multiselect>
            <b-button
              variant="success"
              style="border-radius: 50% !important; height: 40px"
              class="ml-2"
              v-b-modal.modal-add-category
              ><font-awesome-icon class="sidebar-icon" :icon="['fas', 'plus']"
            /></b-button>
          </div>
        </div>
      </div>
      <div v-if="typeSetting === 3" style="padding-top: 2px">
        <form id="create-content" autocomplete="off">
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label cp-label"
              >公開期間設定
            </label>
          </div>
          <div
            class="row row-input"
            style="padding: 0px 40px; margin: 20px 0px"
          >
            <b-form-group class="col-sm-12 px-0 mb-0" v-slot="{ ariaTypeTime }">
              <div class="col-sm-12">
                <b-form-radio
                  v-model="content.expire_radio"
                  class="mr-3 row mb-3 font-weight-bold"
                  :aria-describedby="ariaTypeTime"
                  name="radios-type-time"
                  :value="0"
                  >期間設定（全ユーザー）</b-form-radio
                >
                <div class="row mb-3 row-input">
                  <div
                    class="row-input col-sm-4 col-md-4 mb-3 mb-sm-0 col-lg-3"
                  >
                    <div
                      class="fd-admin-listproperty-card-filter filter-date d-flex"
                    >
                      <date-picker
                        name="date"
                        class="w-100 my-0"
                        v-model="content.start_access_date"
                        :config="optionsDate"
                        autocomplete="off"
                        language="jp"
                      ></date-picker>
                    </div>
                  </div>
                  <span
                    class="col-auto custom-span text-center justify-content-center px-0"
                    style="line-height: 25px"
                    >~</span
                  >
                  <div
                    class="row-input col-sm-4 col-md-4 mb-3 mb-sm-0 col-lg-3"
                  >
                    <div
                      class="fd-admin-listproperty-card-filter filter-date d-flex"
                    >
                      <date-picker
                        name="date"
                        class="w-100 mr-2 my-0"
                        v-model="content.end_access_date"
                        :config="optionsDate"
                        autocomplete="off"
                      ></date-picker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <b-form-radio
                  v-model="content.expire_radio"
                  class="mr-3 row mb-3 font-weight-bold"
                  name="radios-type-time"
                  :aria-describedby="ariaTypeTime"
                  :value="1"
                  >購入後の期限設定（ユーザー毎）
                </b-form-radio>
                <div class="row row-input d-flex">
                  <div
                    class="row-input col-sm-4 col-md-4 mb-3 mb-sm-0 col-lg-3 col-7"
                  >
                    <div
                      class="fd-admin-listproperty-card-filter filter-date d-flex"
                    >
                      <input
                        v-model="content.number_public_date"
                        class="form-input m-0 w-100 my-0"
                        type="number"
                        :maxlength="10"
                        :min="0"
                        oninvalid="this.setCustomValidity('番号を入力してください')"
                        oninput="this.setCustomValidity('')"
                        onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                      />
                    </div>
                  </div>
                  <span
                    class="col-auto text-center justify-content-center my-auto"
                    >日まで</span
                  >
                </div>
              </div>
            </b-form-group>
          </div>
        </form>
      </div>
      <div v-if="typeSetting === 4" style="padding-top: 2px">
        <form id="create-content" autocomplete="off">
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label cp-label"
              >簡易登録設定（パスワード入力が省略されます）</label
            >
            <b-form-group v-slot="{}" class="col-12 cp-4">
              <b-form-checkbox
                v-model="content.is_quick_signup"
                id="click-content"
                name="click-content"
                class="form-checbox-n"
                value="1"
                unchecked-value="0"
                style="min-width: 300px"
                >簡易登録を可能にする</b-form-checkbox
              >
            </b-form-group>
          </div>
          <!-- <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label cp-label"
              >コンテンツ付与用会員登録URL</label
            >
            <div class="col-12 cp-4">
              <input
                class="form-input m-0"
                type="text"
                v-model="content.url_register_provide_show"
                placeholder=""
                disabled
              />
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label cp-label"
              >コンテンツ付与用ログインURL</label
            >
            <div class="col-12 cp-4">
              <input
                class="form-input m-0"
                type="text"
                v-model="content.url_login_provide_show"
                placeholder=""
                disabled
              />
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label cp-label"
              >コンテンツ付与後リダイレクトURL</label
            >
            <div class="col-12 cp-4">
              <input
                class="form-input m-0"
                type="text"
                v-model="content.url_redirect_provide"
                placeholder=""
              />
            </div>
          </div> -->
        </form>
      </div>
      <div class="d-flex justify-content-center">
        <b-button
          v-on:click.prevent="save()"
          class="btn-color sale-btn"
          type="submit"
          :disabled="isLoading"
        >
          <b-spinner v-if="isLoading" small class="mb-1"></b-spinner>
          <span v-if="!isEdit">登録</span>
          <span v-else> 更新する</span>
        </b-button>
      </div>
    </div>
    <div>
      <b-modal id="modal-confirm-content" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>
            保存が完了していません。他のページに移動しても問題ありませんか？
          </h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            class="mx-2 mt-3 btn btn-accept-confirm"
            block
            :disabled="isLoadingConfirm"
            v-on:click.prevent="returnSaleContentScreen()"
          >
            <b-spinner v-if="isLoadingConfirm" small></b-spinner>
            はい
          </b-button>
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            :disabled="isLoadingConfirm"
            @click="$bvModal.hide('modal-confirm-content')"
          >
            いいえ
          </b-button>
        </div>
      </b-modal>
      <b-modal
        id="modal-add-category"
        size="lg"
        title="カテゴリを作成"
        hide-footer
        @show="resetModalCategory"
        @hidden="resetModalCategory"
      >
        <CRow>
          <CCol sm="12">
            <div class="d-flex flex-column">
              <CInput label="カテゴリ名" v-model="category_name_new" />
            </div>
          </CCol>
          <CCol sm="12">
            <div class="d-flex justify-content-center">
              <button
                v-on:click.prevent="createCategory()"
                variant="success"
                class="mr-4 btn-save"
                :disabled="loadingCategory"
              >
                <b-spinner v-if="loadingCategory" small></b-spinner>
                <span>作成</span>
              </button>
              <b-button
                variant="secondary"
                @click="$bvModal.hide('modal-add-category')"
                class="mx-2"
                >閉じる</b-button
              >
            </div>
          </CCol>
        </CRow>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import $ from "jquery";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/solid.css";
import draggable from "vuedraggable";
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import moment from "moment";
import { Urls } from "../../utils/urls.js";
import { Api } from "../../utils/http-common.js";

import "vue-advanced-cropper/dist/style.css";

import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: "far fa-clock",
    date: "far fa-calendar",
    up: "fas fa-arrow-up",
    down: "fas fa-arrow-down",
    previous: "fas fa-chevron-left",
    next: "fas fa-chevron-right",
    today: "fas fa-calendar-check",
    clear: "far fa-trash-alt",
    close: "far fa-times-circle",
  },
});

export default {
  name: "CreateContent",
  components: {
    draggable,
    Tables,
    Cropper,
  },
  data() {
    return {
      x_state: "",
      x_state_1: "",
      x_state_2: "",
      x_show: "my-auto position-absolute",
      x_hide: "my-auto position-absolute d-none",
      show_modal: false,
      isLoading: false,
      listSetting: [
        { value: 1, text: "基本設定" },
        { value: 2, text: "詳細設定" },
        { value: 3, text: "期間設定" },
        // { value: 4, text: "内容設定" },
        { value: 4, text: "登録関係" },
      ],
      typeSetting: 1,
      listAuthorOptions: [],
      listSlagOptions: [],
      category_name_new: "",
      loadingCategory: false,
      content: {
        id: "",
        no: localStorage.getItem(Constants.NO_CONTENT),
        title: "",
        categories: [],
        author_id: "",
        author_name: "",
        img: "",
        product_type: 0,
        start_access_date: "",
        end_access_date: "",
        imagePre: "",
        fileName: "",
        expire_radio: 0,
        condition_display_library: 0,
        slags_basic: [],
        slags_basic1: [],
        slags_basic2: [],
        slag_url_display: "",
        slag_hidden: [],
        slag_url_hidden: "",
        //
        content_basic: [],
        content_basic1: [],
        content_basic2: [],
        content_url_display: "",
        content_hidden: [],
        content_url_hidden: "",
        imgCheck: "",
        number_public_date: "",
        design_settings: 0,
        content_url_redirect: "",
        content_url_cancel: "",
        url_register_provide: "",
        url_login_provide: "",
        url_redirect_provide: "",
        url_register_provide_show: "",
        url_login_provide_show: "",
        is_quick_signup: 0,
      },
      optionsDate: {
        //option for type date
        format: "YYYY-MM-DD LT",
        useCurrent: false,
        showClear: false,
        showClose: false,
        locale: "ja",
      },
      isEdit: false,
      option: {
        //option crop img
        img: "",
        size: 1,
        full: false,
        canMove: true,
        fixedBox: false,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        autoCropWidth: 200,
        autoCropHeight: 200,
        centerBox: false,
        high: true,
        max: 99999,
      },
      fixed: true,
      fixedNumber: [1, 1],
      previews: {},
      show: true,
      check: false,
      shop_id: JSON.parse(
        localStorage.getItem(
          Constants.ADMIN_USER_INFO +
            (this.$route.params.shopId
              ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
              : "_" + Constants.DOMAIN)
        )
      ).shop_id,
      constant_display: Constants.CONDITION_DISPLAY_CONTENT,
      admin_info: JSON.parse(
        localStorage.getItem(
          Constants.ADMIN_USER_INFO +
            (this.$route.params.shopId
              ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
              : "_" + Constants.DOMAIN)
        )
      ),
      type_author: Constants.USER_TYPE_DEFAULT["author"],
      dataTableContent: [],
      objectSelected: [],
      design_settings: 0,
      dataListPage: [],
      dataObject: {
        titlePage: null,
        page: [],
        id: null,
      },
      dataModalAddPage: [],
      dataObjectSlected: [],
      dataOptionTitlePage: [],
      showAddPage: null,
      selectAdd: 1,
      content_condition_1: [],
      content_condition_2: [],
      content_condition_3: [],
      content_condition_4: [],
      slag_condition_1: [],
      slag_condition_2: [],
      slag_condition_3: [],
      slag_condition_4: [],
      isShowFooter: true,
      opDisable: [],
      listContentOptions: [],
      loadingContent: null,
      errorContentIdRequired: false,
      indexDataChapter: null,
      indexDataPage: null,
      disabledConditionContent2: false,
      disabledConditionContent3: false,
      disabledConditionSlag2: false,
      disabledConditionSlag3: false,
      disabledConditionContentPage2: false,
      disabledConditionContentPage3: false,
      disabledConditionSlagPage2: false,
      disabledConditionSlagPage3: false,
      indexChapterSelect: null,
      //modal
      key_word: "",
      fields: tableFields.MODAL_PAGE,
      isLoadingConfirm: false,
      listCategoryOptions: [],
      showAddTableOfContent: false,
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.isEdit = true;
      const reqData = {
        id: this.$route.params.id,
        shop_id: this.shop_id,
      };
      await this.getContentById(reqData);
    } else {
      if (this.admin_info.user_type == this.type_author) {
        this.content.author_name =
          this.admin_info.last_name + this.admin_info.first_name;
        this.content.author_id = this.admin_info.user_id;
      } else {
        await this.getListSettingShopAdmin({
          domain: Constants.DOMAIN,
          shop_id: this.$route.params.shopId,
        });
      }
    }
    const request = {
      shop_id: this.shop_id,
    };
    this.getListAuthorInShop(request);
    this.getALLlistSlag(request);
    this.getListPageOption(request);
    this.getListCategoriesInShop(request);
    const dataRequest = {
      page: 1,
      data: {
        shop_id: this.shop_id,
        limit: "all",
      },
    };
    this.getListContents(dataRequest);
    this.getAllChapters(1);
    const dataChapterRequest = {
      shop_id: this.shop_id,
      content_id: this.$route.params.id,
    };
    this.getListChapterSetting(dataChapterRequest);
  },
  watch: {
    //Author
    listAuthor() {
      if (this.listAuthor.length > 0) {
        this.listAuthorOptions = this.listAuthor.map((item) => ({
          text:
            item && item.name && item.name.length > Constants.MAX_LENGTH
              ? item.name.substr(0, Constants.MAX_LENGTH) + "..."
              : item.name,
          value: item.id.toString(),
        }));
      } else {
        this.listAuthorOptions = [
          { text: "リストは空です", value: null, disabled: true },
        ];
      }
    },
    contentById() {
      this.isEdit = true;
      this.content.title = this.contentById.title;
      if (this.contentById.categorys && this.contentById.categorys.length > 0) {
        this.content.categories = this.contentById.categorys.map((item) => ({
          category_id: item.id,
          category_name: item.category_name,
        }));
      }
      this.content.author_id = this.contentById.user_id;
      this.content.imagePre = this.contentById.image_url
        ? process.env.VUE_APP_ROOT_BACKEND +
          "/storage/" +
          this.contentById.image_url
        : "";
      this.content.product_type = this.contentById.product_type;
      this.content.expire_radio = this.contentById.expire_radio;
      this.content.start_access_date =
        this.contentById.expire_radio == Constants.EXPIRE_CONTENT_LIB["all"]
          ? this.contentById.start_date
          : "";
      this.content.end_access_date =
        this.contentById.expire_radio == Constants.EXPIRE_CONTENT_LIB["all"]
          ? this.contentById.end_date
          : "";
      this.content.number_public_date =
        this.contentById.expire_radio == Constants.EXPIRE_CONTENT_LIB["each"]
          ? this.contentById.number_public_date
          : "";
      this.content.design_settings = this.contentById.design_settings;
      this.content.condition_display_library =
        this.contentById.condition_display_library;
      this.content.content_url_redirect = this.contentById.content_url_redirect
        ? this.contentById.content_url_redirect
        : "";
      this.content.content_url_cancel = this.contentById.content_url_cancel
        ? this.contentById.content_url_cancel
        : "";
      if (
        this.content.condition_display_library ==
        Constants.CONDITION_DISPLAY_CONTENT["SET_CONTENT"]
      ) {
        if (
          this.contentById.content_id_1 &&
          this.contentById.content_id_1.length > 0
        ) {
          this.content.content_basic = this.contentById.content_id_1.map(
            (item) => ({
              value: item.id,
              text: item.title,
            })
          );
        }
        if (
          this.contentById.content_id_2 &&
          this.contentById.content_id_2.length > 0
        ) {
          this.content.content_basic1 = this.contentById.content_id_2.map(
            (item) => ({
              value: item.id,
              text: item.title,
            })
          );
        }
        if (
          this.contentById.content_id_3 &&
          this.contentById.content_id_3.length > 0
        ) {
          this.content.content_basic2 = this.contentById.content_id_3.map(
            (item) => ({
              value: item.id,
              text: item.title,
            })
          );
        }
        if (
          this.contentById.content_id_4 &&
          this.contentById.content_id_4.length > 0
        ) {
          this.content.content_hidden = this.contentById.content_id_4.map(
            (item) => ({
              value: item.id,
              text: item.title,
            })
          );
        }
      } else if (
        this.content.condition_display_library ==
        Constants.CONDITION_DISPLAY_CONTENT["SET_SLAG"]
      ) {
        if (
          this.contentById.content_slag_id_1 &&
          this.contentById.content_slag_id_1.length > 0
        ) {
          this.content.slags_basic = this.contentById.content_slag_id_1.map(
            (item) => ({
              slag_id: item.id,
              slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
            })
          );
        }
        if (
          this.contentById.content_slag_id_2 &&
          this.contentById.content_slag_id_2.length > 0
        ) {
          this.content.slags_basic1 = this.contentById.content_slag_id_2.map(
            (item) => ({
              slag_id: item.id,
              slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
            })
          );
        }
        if (
          this.contentById.content_slag_id_3 &&
          this.contentById.content_slag_id_3.length > 0
        ) {
          this.content.slags_basic2 = this.contentById.content_slag_id_3.map(
            (item) => ({
              slag_id: item.id,
              slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
            })
          );
        }
        if (
          this.contentById.content_slag_id_4 &&
          this.contentById.content_slag_id_4.length > 0
        ) {
          this.content.slag_hidden = this.contentById.content_slag_id_4.map(
            (item) => ({
              slag_id: item.id,
              slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
            })
          );
        }
      }
      this.content.url_redirect_provide = this.contentById.url_redirect_provide;
      this.content.url_login_provide = this.contentById.url_login_provide;
      this.content.url_register_provide = this.contentById.url_register_provide;
      this.content.url_register_provide_show = this.contentById
        .url_register_provide
        ? window.location.origin +
          (this.$route.params.shopId
            ? "/shop/" + this.$route.params.shopId
            : "") +
          this.contentById.url_register_provide
        : "";
      this.content.url_login_provide_show = this.contentById.url_login_provide
        ? window.location.origin +
          (this.$route.params.shopId
            ? "/shop/" + this.$route.params.shopId
            : "") +
          this.contentById.url_login_provide
        : "";
      this.content.is_quick_signup = this.contentById.is_quick_signup;
    },
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listSlag() {
      this.listSlagOptions = this.listSlag.map((item) => ({
        slag_id: item.id,
        slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
      }));
    },
    listAllChapter() {
      for (let i = 0; i < this.listAllChapter.data.length; i++) {
        let dataOptionName = {
          value: null,
          text: null,
        };
        dataOptionName.value = this.listAllChapter.data[i].id;
        dataOptionName.text = this.listAllChapter.data[i].title;
        this.dataOptionTitlePage.push(dataOptionName);
      }
    },
    listPage() {
      this.dataListPage = this.listPage.data;
    },
    listChapterSetting() {
      this.listChapterSetting.data.sort((a, b) =>
        a.position > b.position ? 1 : b.position > a.position ? -1 : 0
      );
      for (var i = 0; i < this.listChapterSetting.data.length; i++) {
        if (this.listChapterSetting.data[i].title) {
          this.listChapterSetting.data[i].catalogue_title =
            this.listChapterSetting.data[i].title;
          this.$set(this.listChapterSetting.data[i], "is_page", 0);
          this.listChapterSetting.data[i].catalogue_type =
            this.listChapterSetting.data[i].type;
        }
        this.disableOptionTitlepage(
          this.listChapterSetting.data[i].catalogue_id,
          i
        );
      }
      this.dataTableContent = this.listChapterSetting.data;
      this.checkShowAddTableOfContent(this.dataTableContent);
    },
    listContents() {
      let listContents = [];
      listContents = this.listContents.map((item) => ({
        value: item.id,
        text: item.title,
      }));
      listContents.forEach((element) => {
        this.listContentOptions.push(element);
      });
      this.loadingContent = false;
    },
    ListSettingShop() {
      if (
        !this.$route.params.id &&
        this.admin_info.user_type != this.type_author
      ) {
        this.dataShop = this.ListSettingShop;
        if (this.dataShop.length > 0) {
          this.content.author_id = this.dataShop[0].author_default
            ? this.dataShop[0].author_default
            : "";
        }
      }
    },
    listCategoriesInShop() {
      this.listCategoryOptions = this.listCategoriesInShop.map((item) => ({
        category_name: item.category_name,
        category_id: item.id.toString(),
      }));
    },
  },
  computed: {
    ...mapGetters([
      "listAuthor",
      "contentById",
      "listSlag",
      "listPage",
      "message",
      "success",
      "error",
      "listChapterSetting",
      "ChapterSettingByContent",
      "listAllChapter",
      "dataConditionChapter",
      "listContents",
      "ListSettingShop",
      "listCategoriesInShop",
    ]),
    listDataPage() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dataListPage = this.listPage.data;
      if (this.key_word != "" && this.dataListPage.length) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dataListPage = this.dataListPage.filter((el) => {
          return el.page_title.search(this.key_word) != -1;
        });
      }
      return this.dataListPage;
    },
  },
  methods: {
    ...mapActions({
      CreateContent: "CreateContent",
      getListAuthorInShop: "getListAuthorInShop",
      getContentById: "getContentById",
      getListContent: "getListContent",
      getALLlistSlag: "getALLlistSlag",
      getListPageOption: "getListPageOption",
      getListChapterSetting: "getListChapterSetting",
      getChapterSetting: "getChapterSetting",
      getAllChapter: "getAllChapter",
      getListContents: "getListContents",
      getListSettingShopAdmin: "getListSettingShopAdmin",
      getListCategoriesInShop: "getListCategoriesInShop",
    }),

    moment,

    async save() {
      this.isLoading = true;
      let shop_id = this.shop_id;
      let id = this.$route.params.id ? this.$route.params.id : "";
      if (
        this.content.start_access_date &&
        this.content.end_access_date &&
        this.content.start_access_date > this.content.end_access_date
      ) {
        this.$toasted.error("終了日には、開始日より後の日付を指定してください");
        this.isLoading = false;
      } else if (
        this.content.content_url_display &&
        !this.content.content_url_display.match(Constants.REGEX_URL)
      ) {
        this.isLoading = false;
        this.$toasted.error(
          "表示条件を満たしていない場合のリダイレクト先URLの形式が正しくありません"
        );
      } else if (
        this.content.content_url_hidden &&
        !this.content.content_url_hidden.match(Constants.REGEX_URL)
      ) {
        this.isLoading = false;
        this.$toasted.error(
          "非表示条件に該当する場合のリダイレクト先URLの形式が正しくありません"
        );
      } else if (
        this.content.slag_url_display &&
        !this.content.slag_url_display.match(Constants.REGEX_URL)
      ) {
        this.isLoading = false;
        this.$toasted.error(
          "非表示条件に該当する場合のリダイレクト先URLの形式が正しくありません"
        );
      } else if (
        this.content.slag_url_hidden &&
        !this.content.slag_url_hidden.match(Constants.REGEX_URL)
      ) {
        this.isLoading = false;
        this.$toasted.error(
          "非表示条件に該当する場合のリダイレクト先URLの形式が正しくありません"
        );
      } else {
        //chapter
        const listChpaters = [];
        this.dataTableContent.forEach((element) => {
          if (element.page.length > 0) {
            if (element.is_page === 0 && element.catalogue_title === null) {
              element["isValidate"] = true;
            } else {
              element["isValidate"] = null;
            }
            listChpaters.push(element);
          }
        });
        //upload img
        var urlImg = "";
        var checkImg = true;
        if (this.content.img) {
          urlImg = await this.handleImageAdded(this.content.img);
          if (!urlImg) {
            checkImg = false;
          }
        } else {
          if (id) {
            urlImg = this.content.imagePre;
          }
        }
        //cut domain + storage for new url (request change)
        if (urlImg) {
          urlImg = urlImg.replace(
            location.protocol +
              "//" +
              location.hostname +
              (location.port ? ":" + location.port : "") +
              "/be/storage/",
            ""
          );
          urlImg = urlImg.replace(
            process.env.VUE_APP_ROOT_BACKEND + "/storage/",
            ""
          );
        }
        if (checkImg) {
          const request = {
            shop_id: shop_id,
            id: id,
            title: this.content.title,
            author_id: this.content.author_id,
            categories: [],
            image_url: urlImg,
            product_type: this.content.product_type,
            duplicate_purchase: this.content.duplicate_purchase,
            expire_radio: this.content.expire_radio,
            start_date: this.content.start_access_date
              ? this.content.start_access_date
              : "",
            end_date: this.content.end_access_date
              ? this.content.end_access_date
              : "",
            number_public_date: this.content.number_public_date,
            design_settings: this.content.design_settings,
            condition_display_library: this.content.condition_display_library,
            content_id_1: [],
            content_id_2: [],
            content_id_3: [],
            content_id_4: [],
            content_slag_id_1: [],
            content_slag_id_2: [],
            content_slag_id_3: [],
            content_slag_id_4: [],
            is_quick_signup: parseInt(this.content.is_quick_signup),
            content_url_redirect: this.content.content_url_redirect
              ? this.content.content_url_redirect
              : "",
            content_url_cancel: this.content.content_url_cancel
              ? this.content.content_url_cancel
              : "",
            url_register_provide: this.content.url_register_provide
              ? this.content.url_register_provide
              : "",
            url_redirect_provide: this.content.url_redirect_provide
              ? this.content.url_redirect_provide
              : "",
            url_login_provide: this.content.url_login_provide
              ? this.content.url_login_provide
              : "",
            catelogue: JSON.stringify(listChpaters),
          };

          //category
          if (this.content.categories && this.content.categories.length > 0) {
            this.content.categories.forEach((value) => {
              request.categories.push(value.category_id);
            });
          }
          //content condition
          if (
            this.content.condition_display_library ==
            this.constant_display["SET_CONTENT"]
          ) {
            if (
              this.content.content_basic &&
              this.content.content_basic.length > 0
            ) {
              this.content.content_basic.forEach((value) => {
                request.content_id_1.push(value.value);
              });
            }
            if (
              this.content.content_basic.length > 0 &&
              this.content.content_basic1 &&
              this.content.content_basic1.length > 0
            ) {
              this.content.content_basic1.forEach((value) => {
                request.content_id_2.push(value.value);
              });
            }
            if (
              this.content.content_basic.length > 0 &&
              this.content.content_basic1.length > 0 &&
              this.content.content_basic2 &&
              this.content.content_basic2.length > 0
            ) {
              this.content.content_basic2.forEach((value) => {
                request.content_id_3.push(value.value);
              });
            }
            if (
              this.content.content_hidden &&
              this.content.content_hidden.length > 0
            ) {
              this.content.content_hidden.forEach((value) => {
                request.content_id_4.push(value.value);
              });
            }
          } else if (
            this.content.condition_display_library ==
            this.constant_display["SET_SLAG"]
          ) {
            if (
              this.content.slags_basic &&
              this.content.slags_basic.length > 0
            ) {
              this.content.slags_basic.forEach((value) => {
                request.content_slag_id_1.push(value.slag_id);
              });
            }
            if (
              this.content.slags_basic.length > 0 &&
              this.content.slags_basic1 &&
              this.content.slags_basic1.length > 0
            ) {
              this.content.slags_basic1.forEach((value) => {
                request.content_slag_id_2.push(value.slag_id);
              });
            }
            if (
              this.content.slags_basic.length > 0 &&
              this.content.slags_basic1.length > 0 &&
              this.content.slags_basic2 &&
              this.content.slags_basic2.length > 0
            ) {
              this.content.slags_basic2.forEach((value) => {
                request.content_slag_id_3.push(value.slag_id);
              });
            }
            if (
              this.content.slag_hidden &&
              this.content.slag_hidden.length > 0
            ) {
              this.content.slag_hidden.forEach((value) => {
                request.content_slag_id_4.push(value.slag_id);
              });
            }
          }
          const checkStatusValidate = listChpaters.filter(
            (item) => item.is_page === 0 && item.isValidate === true
          );
          if (checkStatusValidate.length < 1) {
            if (id) {
              const dataReturn = await this.$store.dispatch(
                "updateSetBasicContent",
                request
              );
              if (dataReturn.success) {
                this.isLoading = false;
              } else {
                this.isLoading = false;
              }
            } else {
              const dataReturn = await this.$store.dispatch(
                "createBasicContent",
                request
              );
              if (dataReturn.success) {
                this.isLoading = false;
                this.$router.push({
                  name: this.$route.params.shopId
                    ? "list content"
                    : "list content domain",
                });
              } else {
                this.isLoading = false;
              }
            }
          } else {
            this.$toasted.error("タイトルチャプターを選べてください。");
            this.isLoading = false;
          }
        }
      }
    },

    returnList() {
      this.$router.go(-1);
    },
    uploadImg(e) {
      if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
        this.$toasted.error("图片类型必须是.jpeg,jpg,png中的一种");
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
        return false;
      } else if (
        e.target.files[0] &&
        e.target.files[0].size / 1024 / 1024 > 100
      ) {
        this.$toasted.error("画像1枚につき100MBまで");
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
        return false;
      } else {
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
          let data;
          if (typeof e.target.result === "object") {
            data = window.URL.createObjectURL(new Blob([e.target.result]));
          } else {
            data = e.target.result;
          }
          this.option.img = data;
        };
        reader.readAsArrayBuffer(file);
        this.content.fileName = file.name;
        this.content.imagePre = null;
      }
    },
    realTime(data) {
      this.previews = data.image;
      const dataImage = data.canvas.toDataURL("image/jpeg");
      if (this.option.img) {
        var arr = dataImage.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        this.content.img = new File([u8arr], this.content.fileName, {
          type: mime,
        });
        this.content.imgCheck = dataImage;
      }
    },

    async handleImageAdded(file) {
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/jpg"
      ) {
        this.$toasted.error("画像がjpg、jpeg、png　形式である必要があります。");
        this.isLoading = false;
      } else {
        if (file.size / 1024 / 1024 > 100) {
          this.$toasted.error("画像1枚につき100MBまで");
          this.isLoading = false;
        } else {
          var formData = new FormData();
          formData.append("image", file);
          formData.append("id", this.shop_id);

          const url = `${Urls.CONTENT}/${Urls.UPLOAD}`;
          const method = "POST";
          return await Api.adminRequestServer({
            method,
            url,
            data: formData,
          })
            .then((response) => {
              return response.data.data; // Get url from response
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    //chapter
    getAllChapters(page) {
      if (page === undefined) {
        page = 1;
      }
      const formData = {
        page: page,
        data: {
          shop_id: this.shop_id,
        },
      };
      this.getAllChapter(formData);
    },
    ClickPageChecked(object) {
      this.$set(object, "_checked", true);
      this.dataObject = {
        title: null,
        page: [],
        catalogue_id: null,
        is_page: 0,
        content_id_1: [],
        content_id_2: [],
        content_id_3: [],
        slag_id_1: [],
        slag_id_2: [],
        slag_id_3: [],
        position: this.dataTableContent.length + 1,
      };
      if (this.objectSelected.find((item) => item.id === object.id)) {
        this.objectSelected.splice(this.objectSelected.indexOf(object), 1);
        this.$set(object, "_checked", false);
      } else {
        const page = {
          id: object.id,
          page_title: object.page_title,
          content_id_1: [],
          content_id_2: [],
          content_id_3: [],
          slag_id_1: [],
          slag_id_2: [],
          slag_id_3: [],
        };
        this.objectSelected.push(page);
      }
      this.dataObject.page = this.objectSelected;
    },
    ClickAddPage(object) {
      this.dataObject = {
        title: object.page_title,
        page: [],
        catalogue_id: null,
        is_page: 1,
        content_id_1: [],
        content_id_2: [],
        content_id_3: [],
        slag_id_1: [],
        slag_id_2: [],
        slag_id_3: [],
        position: this.dataTableContent.length + 1,
      };
      this.dataListPage.forEach((element) => {
        if (element.id === object.id) {
          this.$set(element, "_checked", true);
          this.objectSelected = [];
          const page = {
            id: object.id,
            page_title: object.page_title,
            content_id_1: [],
            content_id_2: [],
            content_id_3: [],
            slag_id_1: [],
            slag_id_2: [],
            slag_id_3: [],
          };
          this.objectSelected.push(page);
        } else {
          this.$set(element, "_checked", false);
        }
      });
      this.dataObject.page = this.objectSelected;
    },

    deletePage(index, objectPage, indexChapter) {
      this.dataTableContent[indexChapter].page.splice(index, 1);
      this.show_modal = true;
      this.auto_remove();
      this.checkShowAddTableOfContent(this.dataTableContent);
    },
    auto_remove() {
      setTimeout(() => {
        this.show_modal = false;
      }, 2000);
    },

    deleteContent(index) {
      this.dataTableContent.splice(index, 1);
      this.show_modal = true;
      this.auto_remove();
      this.checkShowAddTableOfContent(this.dataTableContent);
    },
    AddNewChapter() {
      this.isShowFooter = true;
      this.selectAdd = 1;
      this.showAddPage = 0;
      this.$refs["modal-show-page"].show();
      this.dataObject = {
        titlePage: null,
        page: [],
        id: parseInt(this.dataTableContent.length) + 1,
      };
      this.objectSelected = [];
      if (this.dataListPage.length) {
        this.dataListPage
          .filter((x) => x._checked == true)
          .forEach(function (item) {
            item._checked = false;
          });
      }
    },
    closeModal() {
      this.$refs["modal-show-page"].hide();
      this.key_word = "";
    },
    OpenModalPage(items, ind) {
      this.selectAdd === 1;
      this.dataObjectSlected = [];
      this.dataModalAddPage = items;
      this.indexChapterSelect = ind;
      this.$refs["modal-add-page"].show();
    },
    addPageInChapter() {
      this.selectAdd = 1;
      this.dataObjectSlected.forEach((element) => {
        const getData = this.dataTableContent[
          this.indexChapterSelect
        ].page.filter((item) => item.id === element.id);
        if (getData.length < 1) {
          const page = {
            id: element.id,
            page_title: element.page_title,
            content_id_1: [],
            content_id_2: [],
            content_id_3: [],
            slag_id_1: [],
            slag_id_2: [],
            slag_id_3: [],
          };
          this.dataModalAddPage["page"].push(page);
        }
      });
      this.dataListPage
        .filter((x) => x._selected == true)
        .forEach(function (item) {
          item._selected = false;
        });
      this.$refs["modal-add-page"].hide();
      this.dataObjectSlected = [];
      this.key_word = "";
    },
    closeModalAddPage() {
      this.$refs["modal-add-page"].hide();
      this.dataObjectSlected = [];
      this.dataListPage
        .filter((x) => x._selected == true)
        .forEach(function (item) {
          item._selected = false;
        });
      this.key_word = "";
    },
    ClickPageAddChecked(object) {
      this.$set(object, "_selected", true);
      if (this.dataObjectSlected.find((item) => item.id === object.id)) {
        this.dataObjectSlected.splice(
          this.dataObjectSlected.indexOf(object),
          1
        );
        this.$set(object, "_selected", false);
      } else {
        this.dataObjectSlected.push(object);
      }
      this.dataListPage = this.dataObjectSlected;
    },

    disableOptionTitlepage(value, index) {
      this.opDisable = [];
      this.dataTableContent.map((item, ind) => {
        if (
          item.catalogue_id &&
          ind != index &&
          !this.opDisable.includes(item.catalogue_id)
        ) {
          this.opDisable.push(item.catalogue_id);
        }
      });
      if (!this.opDisable.includes(value)) {
        this.opDisable.push(value);
      }
    },
    showSlagConditionDetail(item, index) {
      this.indexDataChapter = index;
      const listOptionsContent = this.listContentOptions.reduce(
        (obj, cur) => ({ ...obj, [cur.value]: cur }),
        {}
      );
      const listOptionsSlag = this.listSlagOptions.reduce(
        (obj, cur) => ({ ...obj, [cur.slag_id]: cur }),
        {}
      );
      if (this.dataTableContent[this.indexDataChapter].content_id_1) {
        this.content_condition_1 = this.fomatObject(
          this.dataTableContent[this.indexDataChapter].content_id_1,
          listOptionsContent
        );
      } else {
        this.content_condition_1 = null;
      }
      if (this.dataTableContent[this.indexDataChapter].content_id_2) {
        this.content_condition_2 = this.fomatObject(
          this.dataTableContent[this.indexDataChapter].content_id_2,
          listOptionsContent
        );
      } else {
        this.content_condition_2 = null;
      }
      if (this.dataTableContent[this.indexDataChapter].content_id_3) {
        this.content_condition_3 = this.fomatObject(
          this.dataTableContent[this.indexDataChapter].content_id_3,
          listOptionsContent
        );
      } else {
        this.content_condition_3 = null;
      }
      if (this.dataTableContent[this.indexDataChapter].content_id_4) {
        this.content_condition_4 = this.fomatObject(
          this.dataTableContent[this.indexDataChapter].content_id_4,
          listOptionsContent
        );
      } else {
        this.content_condition_4 = null;
      }
      if (this.dataTableContent[this.indexDataChapter].slag_id_1) {
        this.slag_condition_1 = this.fomatObject(
          this.dataTableContent[this.indexDataChapter].slag_id_1,
          listOptionsSlag
        );
      } else {
        this.slag_condition_1 = null;
      }
      if (this.dataTableContent[this.indexDataChapter].slag_id_2) {
        this.slag_condition_2 = this.fomatObject(
          this.dataTableContent[this.indexDataChapter].slag_id_2,
          listOptionsSlag
        );
      } else {
        this.slag_condition_2 = null;
      }
      if (this.dataTableContent[this.indexDataChapter].slag_id_3) {
        this.slag_condition_3 = this.fomatObject(
          this.dataTableContent[this.indexDataChapter].slag_id_3,
          listOptionsSlag
        );
      } else {
        this.slag_condition_3 = null;
      }
      if (this.dataTableContent[this.indexDataChapter].slag_id_4) {
        this.slag_condition_4 = this.fomatObject(
          this.dataTableContent[this.indexDataChapter].slag_id_4,
          listOptionsSlag
        );
      } else {
        this.slag_condition_4 = null;
      }
      this.$bvModal.show("modal-detail-slagCondition");
      this.changeMutiSelectContentCondition();
      this.changeMutiSelectSlagCondition();
    },
    showSlagConditionDetailPage(indexChapter, indexPage) {
      this.indexDataChapter = indexChapter;
      this.indexDataPage = indexPage;
      const listOptionsContent = this.listContentOptions.reduce(
        (obj, cur) => ({ ...obj, [cur.value]: cur }),
        {}
      );
      const listOptionsSlag = this.listSlagOptions.reduce(
        (obj, cur) => ({ ...obj, [cur.slag_id]: cur }),
        {}
      );
      if (this.dataTableContent[indexChapter].page[indexPage].content_id_1) {
        this.content_condition_1 = this.fomatObject(
          this.dataTableContent[indexChapter].page[indexPage].content_id_1,
          listOptionsContent
        );
      } else {
        this.content_condition_1 = null;
      }
      if (this.dataTableContent[indexChapter].page[indexPage].content_id_2) {
        this.content_condition_2 = this.fomatObject(
          this.dataTableContent[indexChapter].page[indexPage].content_id_2,
          listOptionsContent
        );
      } else {
        this.content_condition_2 = null;
      }
      if (this.dataTableContent[indexChapter].page[indexPage].content_id_3) {
        this.content_condition_3 = this.fomatObject(
          this.dataTableContent[indexChapter].page[indexPage].content_id_3,
          listOptionsContent
        );
      } else {
        this.content_condition_3 = null;
      }
      if (this.dataTableContent[indexChapter].page[indexPage].content_id_4) {
        this.content_condition_4 = this.fomatObject(
          this.dataTableContent[indexChapter].page[indexPage].content_id_4,
          listOptionsContent
        );
      } else {
        this.content_condition_4 = null;
      }
      if (this.dataTableContent[indexChapter].page[indexPage].slag_id_1) {
        this.slag_condition_1 = this.fomatObject(
          this.dataTableContent[indexChapter].page[indexPage].slag_id_1,
          listOptionsSlag
        );
      } else {
        this.slag_condition_1 = null;
      }
      if (this.dataTableContent[indexChapter].page[indexPage].slag_id_2) {
        this.slag_condition_2 = this.fomatObject(
          this.dataTableContent[indexChapter].page[indexPage].slag_id_2,
          listOptionsSlag
        );
      } else {
        this.slag_condition_2 = null;
      }
      if (this.dataTableContent[indexChapter].page[indexPage].slag_id_3) {
        this.slag_condition_3 = this.fomatObject(
          this.dataTableContent[indexChapter].page[indexPage].slag_id_3,
          listOptionsSlag
        );
      } else {
        this.slag_condition_3 = null;
      }
      if (this.dataTableContent[indexChapter].page[indexPage].slag_id_4) {
        this.slag_condition_4 = this.fomatObject(
          this.dataTableContent[indexChapter].page[indexPage].slag_id_4,
          listOptionsSlag
        );
      } else {
        this.slag_condition_4 = null;
      }
      this.$bvModal.show("modal-Condition-Page");
      this.changeMutiSelectContentPageCondition();
      this.changeMutiSelectSlagPageCondition();
    },
    addPage() {
      this.objectSelected = [];
      this.dataListPage
        .filter((x) => x._checked == true)
        .forEach(function (item) {
          item._checked = false;
        });
      this.isShowFooter = false;
      this.selectAdd = 2;
      this.showAddPage = 1;
    },
    addChapter() {
      this.objectSelected = [];
      this.dataListPage
        .filter((x) => x._checked == true)
        .forEach(function (item) {
          item._checked = false;
        });
      this.isShowFooter = false;
      this.selectAdd = 2;
      this.showAddPage = 2;
    },
    backMenuAdd() {
      this.objectSelected = [];
      this.isShowFooter = true;
      this.selectAdd = 1;
      this.showAddPage = 0;
      this.key_word = "";
    },
    updateConditionChapter() {
      this.$refs["modal-show-page"].hide();
      this.key_word = "";
      this.dataTableContent.push(this.dataObject);
      this.checkShowAddTableOfContent(this.dataTableContent);
    },
    updateConditionByChapter() {
      this.dataTableContent[this.indexDataChapter].content_id_1 =
        this.converContentCondition(this.content_condition_1);
      this.dataTableContent[this.indexDataChapter].content_id_2 =
        this.converContentCondition(this.content_condition_2);
      this.dataTableContent[this.indexDataChapter].content_id_3 =
        this.converContentCondition(this.content_condition_3);
      this.dataTableContent[this.indexDataChapter].content_id_4 =
        this.converContentCondition(this.content_condition_4);
      this.dataTableContent[this.indexDataChapter].slag_id_1 =
        this.converSlagCondition(this.slag_condition_1);
      this.dataTableContent[this.indexDataChapter].slag_id_2 =
        this.converSlagCondition(this.slag_condition_2);
      this.dataTableContent[this.indexDataChapter].slag_id_3 =
        this.converSlagCondition(this.slag_condition_3);
      this.dataTableContent[this.indexDataChapter].slag_id_4 =
        this.converSlagCondition(this.slag_condition_4);
      this.$bvModal.hide("modal-detail-slagCondition");
    },
    updateConditionByPage() {
      this.dataTableContent[this.indexDataChapter].page[
        this.indexDataPage
      ].content_id_1 = this.converContentCondition(this.content_condition_1);
      this.dataTableContent[this.indexDataChapter].page[
        this.indexDataPage
      ].content_id_2 = this.converContentCondition(this.content_condition_2);
      this.dataTableContent[this.indexDataChapter].page[
        this.indexDataPage
      ].content_id_3 = this.converContentCondition(this.content_condition_3);
      this.dataTableContent[this.indexDataChapter].page[
        this.indexDataPage
      ].content_id_4 = this.converContentCondition(this.content_condition_4);
      this.dataTableContent[this.indexDataChapter].page[
        this.indexDataPage
      ].slag_id_1 = this.converSlagCondition(this.slag_condition_1);
      this.dataTableContent[this.indexDataChapter].page[
        this.indexDataPage
      ].slag_id_2 = this.converSlagCondition(this.slag_condition_2);
      this.dataTableContent[this.indexDataChapter].page[
        this.indexDataPage
      ].slag_id_3 = this.converSlagCondition(this.slag_condition_3);
      this.dataTableContent[this.indexDataChapter].page[
        this.indexDataPage
      ].slag_id_4 = this.converSlagCondition(this.slag_condition_4);
      this.$bvModal.hide("modal-Condition-Page");
    },
    closeModalCondition() {
      this.$bvModal.hide("modal-detail-slagCondition");
    },
    closeModalConditionPage() {
      this.$bvModal.hide("modal-Condition-Page");
    },
    converContentCondition(array) {
      if (array && array.length > 0) {
        const listContentsCondition = [];
        array.map((item) => {
          listContentsCondition.push(item.value);
        });
        return listContentsCondition;
      }
    },
    converSlagCondition(array) {
      if (array && array.length > 0) {
        const listContentsCondition = [];
        array.map((item) => {
          listContentsCondition.push(item.slag_id);
        });
        return listContentsCondition;
      }
    },
    fomatObject(array, object) {
      if (array && array.length > 0) {
        let ArrrayObject = [];
        Object.keys(object)
          .filter((key) => array.includes(parseInt(key)))
          .reduce((obj, key) => {
            ArrrayObject.push(object[key]);
          }, {});
        return ArrrayObject;
      }
    },
    changeMutiSelectContentCondition() {
      if (!this.content_condition_1) {
        this.disabledConditionContent2 = true;
        this.content_condition_2 = null;
        this.content_condition_3 = null;
      } else {
        this.disabledConditionContent2 = false;
      }
      if (!this.content_condition_1 || !this.content_condition_2) {
        this.disabledConditionContent3 = true;
        this.content_condition_3 = null;
      } else {
        this.disabledConditionContent3 = false;
      }
    },
    changeMutiSelectSlagCondition() {
      if (!this.slag_condition_1) {
        this.disabledConditionSlag2 = true;
        this.slag_condition_2 = null;
        this.slag_condition_3 = null;
      } else {
        this.disabledConditionSlag2 = false;
      }
      if (!this.slag_condition_1 || !this.slag_condition_2) {
        this.disabledConditionSlag3 = true;
        this.slag_condition_3 = null;
      } else {
        this.disabledConditionSlag3 = false;
      }
    },
    changeMutiSelectContentPageCondition() {
      if (!this.content_condition_1) {
        this.disabledConditionContentPage2 = true;
        this.content_condition_2 = null;
        this.content_condition_3 = null;
      } else {
        this.disabledConditionContentPage2 = false;
      }
      if (!this.content_condition_1 || !this.content_condition_2) {
        this.disabledConditionContentPage3 = true;
        this.content_condition_3 = null;
      } else {
        this.disabledConditionContentPage3 = false;
      }
    },
    changeMutiSelectSlagPageCondition() {
      if (!this.slag_condition_1) {
        this.disabledConditionSlagPage2 = true;
        this.slag_condition_2 = null;
        this.slag_condition_3 = null;
      } else {
        this.disabledConditionSlagPage2 = false;
      }
      if (!this.slag_condition_1 || !this.slag_condition_2) {
        this.disabledConditionSlagPage3 = true;
        this.slag_condition_3 = null;
      } else {
        this.disabledConditionSlagPage3 = false;
      }
    },

    returnSaleContentScreen() {
      this.isLoadingConfirm = true;
      let id = this.$route.params.id ? this.$route.params.id : "";
      if (this.isEdit) {
        this.$router.push({
          name: this.$route.params.shopId
            ? "Content Sale"
            : "Content Sale domain",
          params: { id: id },
        });
        this.isLoadingConfirm = false;
      } else {
        this.$router.push({
          name: this.$route.params.shopId
            ? "Content Sale Create"
            : "Content Sale Create domain",
          params: { id: id },
        });
        this.isLoadingConfirm = false;
      }
    },

    x_state_fn(int) {
      return int === 1 ? true : false;
    },
    async createCategory() {
      this.loadingCategory = true;
      const dataRequest = {
        shop_id: this.shop_id,
        category_name: this.category_name_new,
        category_id:
          this.category_name_new.substring(0, 2) +
          Math.floor(Math.random() * 10000000),
      };
      const dataReturn = await this.$store.dispatch(
        "CreateCategoryAdmin",
        dataRequest
      );
      if (dataReturn.success) {
        const returnList = await this.$store.dispatch(
          "getListCategoriesInShop",
          {
            shop_id: this.shop_id,
          }
        );
        if (returnList.success) {
          const cat = this.listCategoryOptions.filter(
            (item) => item.category_name === this.category_name_new
          )[0];
          this.content.categories.push({
            category_name: cat.category_name,
            category_id: cat.category_id,
          });
          this.$bvModal.hide("modal-add-category");
          this.loadingCategory = false;
        }
      } else {
        this.$bvModal.hide("modal-add-category");
        this.loadingCategory = false;
      }
    },
    resetModalCategory() {
      this.category_name_new = "";
      this.loadingCategory = false;
    },
    checkShowAddTableOfContent(dataTableContent) {
      if (dataTableContent.length > 0) {
        let isShow = false;
        dataTableContent.forEach((val) => {
          if (val.page.length > 0) {
            isShow = true;
          }
        });
        this.showAddTableOfContent = isShow;
      } else {
        this.showAddTableOfContent = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page-list-content {
  padding: 0px;
  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 0px !important;
  }
}
.custom-label {
  border: 1px solid #b5b5b5;
  padding: 20px;
  width: 100%;
  text-align: left;
  margin: 0px;
}
.customBackGround {
  border-radius: 25px;
  background-color: #d9d9d9;
}
/deep/ .modalCustom > .modal-dialog {
  max-width: 720px !important;
  margin: 1.75rem auto;
}
.customSelectAdd {
  border: 1px solid #00ab8e;
  background-color: #00ab8e;
  color: #fff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  padding: 0.25rem 1rem;
  font-size: 20px;
  line-height: 1.5;
  border-radius: 3px;
  width: 190px;
}
.border-titlePage {
  text-align: center;
  padding: 5px 30px 5px 30px;
  border: 2px solid #d9d9d9;
  margin-top: revert;
  margin-bottom: revert;
  background-color: #f2f2f2;
}
.customListPage {
  .custom-button {
    justify-content: center;
    height: 40px;
    width: 40px;
    background-color: #595959;
    border-radius: 50%;
    border-color: #595959;
    display: block;
    margin-left: 1%;
    position: relative;
    .custom-span {
      position: absolute;
      top: -61%;
      right: 25.6%;
      font-size: 50px;
    }
  }
}
.textPage {
  width: 95%;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.custom-button-add-chapter {
  margin: 20px 0px;
  background-color: #00b797;
  border-color: #00b797;
  color: #fff;
}
.custom-button-add-chapter:not(:disabled):not(.disabled):active,
.show > .custom-button-add-chapter.dropdown-toggle {
  background-color: #00b797;
  border-color: #00b797;
  color: #fff;
}
.btn-tab-sale-active {
  background: #ff644e;
  border: none;
  color: #fff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.75rem 1.5rem;
  font-size: 20px;
  line-height: 1.5;
  border-radius: 3px;
  width: 160px;
}
.btn-tab-sale-inactive {
  color: #636f83;
  border-color: #636f83;
  display: inline-block;
  font-weight: 400;
  color: #3c4b64;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid #636f83;
  padding: 0.75rem 1.5rem;
  font-size: 20px;
  line-height: 1.5;
  border-radius: 3px;
  width: 160px;
}
.btn-tab-sale-active:after {
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -10px;
  content: "";
  display: block;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #ff644e;
}
.btn-toggle-active {
  display: inline-block;
  position: relative;
  .btn-checkbox-active {
    border: 0;
  }
  input[type="radio"] {
    opacity: 0;
    position: absolute;
    z-index: 1;
    width: 100%;
    inset: 0;
    &:checked ~ .btn-checkbox-active {
      background: #ff644e;
      color: #fff;
    }
  }
}
.btn-toggle-active {
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
}
@media (max-width: 1024px) {
  .btn-tab-sale-inactive {
    padding: 0.75rem 1rem;
    font-size: 18px;
    width: 110px;
  }
  .btn-tab-sale-active {
    padding: 0.75rem 1rem;
    font-size: 18px;
    width: 110px;
  }
}
@media (max-width: 450px) {
  .btn-tab-sale-inactive {
    margin-top: 10px;
  }
  .btn-tab-sale-active {
    margin-top: 10px;
  }
  .btn-return-respon {
    margin-top: 5px;
  }
}
.modal-show-page {
  .modal-header {
    padding: 0.5rem 1rem !important;
  }
}
.button-selected {
  background: #e5e5e5;
  padding: 5px 20px;
  color: #000;
  font-size: 17px;
  height: 42px;
}
.table-modal-chapter {
  .table {
    margin-top: 0px !important;
  }
}
.sale-btn {
  width: 120px !important;
  height: 42px !important;
  background-color: #00b797 !important;
  color: #fff !important;
  border-radius: 6px !important;
  font-size: 20px;
}
.sale-btn:hover {
  width: 120px !important;
  height: 42px !important;
  background-color: #00ab82 !important;
  color: #fff !important;
  border-radius: 6px !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
